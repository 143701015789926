/* body {
  overflow: hidden;
} */

button:focus {
  outline: none;
  box-shadow: none;
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.react-trello-card {
  cursor: pointer;
}

.react-trello-board {
  padding: 0 !important;
  max-height: calc(100vh - 170px);
  overflow-y: auto !important;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 50px !important;
}

.react-trello-lane > div::-webkit-scrollbar,
.fc .fc-scroller::-webkit-scrollbar,
.react-trello-board::-webkit-scrollbar,
.featuresPopover::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: rgb(226, 226, 226);
  border-radius: 5px;
}

.react-trello-lane > div::-webkit-scrollbar {
  width: 8px;
}

.react-trello-lane > div::-webkit-scrollbar-thumb,
.fc .fc-scroller::-webkit-scrollbar-thumb,
.react-trello-board::-webkit-scrollbar-thumb,
.featuresPopover::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
}

.react-trello-lane > header > span {
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-trello-lane > div > a {
  display: none;
}

.react-trello-lane > div:nth-child(2) {
  min-width: 100%;
  padding-right: 10px;
}

.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: visible !important;
}

.smooth-dnd-container.vertical {
  min-height: calc(100vh - 200px);
}

.top__nav {
  min-height: 70px;
}

.contact__icons {
  font-size: 14px;
}

.contact__icons a:hover {
  color: #047481;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
  /* border-left: 8px solid #047481 !important; */
  overflow: hidden !important;
  border-radius: 4px;
  box-shadow: 2px 2px 15px 0 rgb(0 0 0 / 15%);
  -moz-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 2px 15px 0 rgb(0 0 0 / 15%);
  font-size: 14px;
}

.react_times_button {
  font-size: 14px;
}

.react-calendar__tile--now {
  background: #047481 !important;
  color: #fff !important;
  border-radius: 3px !important;
}

/* full-calendar */
.fc {
  height: calc(100vh - 190px);
}
.fc .fc-col-header {
  border-bottom: 1px solid gainsboro;
}
.fc .fc-col-header-cell {
  vertical-align: middle;
}
.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
}
.fc .fc-timegrid-col.fc-day-today {
  background: none !important;
}
.fc-header-toolbar.fc-toolbar {
  margin-bottom: 0 !important;
}
.fc .fc-toolbar-chunk {
  font-size: 13px;
}
.fc .fc-button .fc-icon {
  font-size: 1em !important;
}
.fc .fc-toolbar-title {
  font-size: 1em !important;
}
.fc .fc-col-header-cell-cushion {
  padding: 6px 0 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgb(157, 161, 172) !important;
}
.fc .fc-timegrid-axis-cushion {
  font-size: 10px;
}

.fc,
.fc .fc-col-header-cell,
.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid,
.fc-timegrid-slot.fc-timegrid-slot-minor {
  border: none !important;
}

.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
  font-size: 12px !important;
}

.fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important;
}

.fc .eventBody__title {
  font-size: 11px;
}

/* .fc-event{
    height:120px !important;
} */

/* .fc-h-event, .fc-v-event {
    border-style: dashed!important;
    border-width: 2px!important;
    overflow: hidden!important;
} */

/* windmill modal */
div button[aria-label="close"] {
  display: none;
}

.react-datetime-picker__wrapper {
  border: 1px solid #d2d2d2;
  padding: 6px 4px;
  border-radius: 4px;
}

.picker_container {
  margin: 0 auto !important;
}

.tagsInputWrapper > span {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}
.react-tagsinput-input {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  flex: 1;
  min-height: 32px;
  font-size: 16px !important;
  color: #333 !important;
}
.react-tagsinput-input::placeholder {
  font-size: 16px;
  color: #333;
}
/* .react-tagsinput--focused {
  border-color: #047481 !important;
} */

.react-tagsinput-tag {
  background-color: #047481 !important;
  border: 1px solid #047481 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 2px 8px !important;
}
.react-tagsinput--focused {
  border: 2px solid #047481 !important;
}

/* quill styles */
.quill {
  min-height: calc(100vh - 310px);
  max-height: calc(100vh - 310px);
}
.ql-container {
  height: calc(100vh - 360px) !important;
}

@media (min-width: 991px) {
  .dropdown {
    top: 100%;
  }
}

@media (max-width: 991px) {
  .task__wrapper {
    overflow-x: auto;
  }
  .fc {
    min-width: 1500px;
  }
  .fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion {
    padding: 10px 10px !important;
  }
}

@media (max-width: 1560px) {
  .fc .eventBody__title {
    font-size: 10px;
  }
}

.popup-content {
  margin: auto;
  background: transparent !important;
  border: none !important;
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.noaccess-modal-overlay {
  background: rgba(0, 0, 0, 0.401) !important;
  backdrop-filter: blur(3px) !important;
}

.noaccess-modal-content {
  width: 80%;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



#custome_scroll::-webkit-scrollbar-thumb {
  background: transparent;
  height: 100px;
}

#custome_scroll:hover::-webkit-scrollbar-thumb {
  background: #707275ad;
  transition: 0.3s all linear;
}

#custome_scroll::-webkit-scrollbar {
  width: 5px;
  height: 12px;
  background-color: transparent;
  border-radius: 2px;
}

#custome_scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}


.table-container-analytics {
  max-height: 88vh;
  max-width: 100vw;
  overflow-y: auto;
}

.table-container-analytics table {
  border-collapse: collapse;
  width: 100%;
}

/* th, td {
  padding: 8px;
  border: 1px solid #ddd;
} */

.table-container-analytics th {
  position: sticky;
  top: 0;
  background: #f1f5f9;
}

.table-container-analytics th:first-child {
  left: 0;
  z-index: 2;

}

.table-container-analytics td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background: #f1f5f9;

}

.table-container-analytics tfoot th {
  position: sticky;
  bottom: 0;
  background: #f1f5f9;

}

.iconTeam {
  position: absolute;
}

#iconTeam1 {
  animation: moveForward 5s linear infinite;
}

#iconTeam2 {
  animation: moveBackward 5s linear infinite;
}

@keyframes moveForward {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}

@keyframes moveBackward {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}
